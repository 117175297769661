import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { defaultTo, isNil, take } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, Dropdown, H, P, Ui } from "common/components/atoms";
import { OptionsType } from "common/components/atoms/Dropdown/Dropdown";
import { OnboardingCompanySearch } from "common/components/molecules";
import { ChevronRightIcon } from "common/icons/svg";
import { CONTACT_US_LINK, scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import Img from "../../../onboard/company/assets/card-img-1.png";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "onboard.company.aroOnboarding");

const ID = 617;

const AroOnboarding = () => {
  const navigate = useNavigate();

  const user = useStoreState((state) => state.account.user);
  const setAroOnboarding = useStoreActions((actions) => actions.companyOnboardingModel.setAroOnboarding);
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdminDropdownSelectionEnabled, setIsAdminDropdownSelectionEnabled] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(undefined);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompany(e.target.value);
  }, []);

  const createCompanyHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const request = await axios.post("/api/aro/create-company", {
        organizationNumber: selectedCompany,
      });

      if (request.status === 200) {
        await getAccountThunk();

        setAroOnboarding({
          isActive: false,
        });

        navigate(getPath(["aro", "report"], { companyId: request.data.companyId }));
      }
    } catch (e) {
      console.error({ e });
    } finally {
      setIsLoading(false);
    }
  }, [getAccountThunk, navigate, selectedCompany, setAroOnboarding]);

  const submitHandler = async () => {
    if (isAdminDropdownSelectionEnabled) {
      setAroOnboarding({
        isActive: false,
      });

      navigate(getPath(["aro", "report"], { companyId: selectedCompany }));
      return;
    }

    await createCompanyHandler();
  };

  const isAdminAccess = user?.id === ID;
  const companiesList: OptionsType[] = (
    defaultTo([], user?.companies).length > 3 ? take(3, defaultTo([], user?.companies)) : defaultTo([], user?.companies)
  ).map((el) => ({
    id: el.id,
    name: el.name,
  }));

  useEffect(() => {
    if (isAdminAccess && companiesList.length) {
      setIsAdminDropdownSelectionEnabled(true);
    }
  }, [companiesList.length, isAdminAccess]);

  return (
    <div className={classes["wrap"]}>
      <div className="px-6">
        <H.s className="mt-7">{t("title")}</H.s>

        <P.l className="mt-2 mb-6" color="foregroundMedium">
          {isAdminDropdownSelectionEnabled
            ? t("manageCompanies", {
                amount: companiesList.length,
              })
            : t("description")}
        </P.l>

        {isAdminDropdownSelectionEnabled ? (
          <Dropdown
            isSearchable
            optionsIsObject
            options={companiesList}
            label={t("chooseCompany")}
            selectedValue={companiesList?.find((el) => el.id === selectedCompany)?.id}
            onChange={(cmp) => {
              setSelectedCompany(cmp.target.value);
            }}
          />
        ) : (
          <OnboardingCompanySearch onChange={handleChange} selectedValue={selectedCompany} />
        )}

        <Ui.s className="mt-3" color="foregroundMedium">
          {isAdminDropdownSelectionEnabled
            ? t.el("submitShareholderRegister", {
                components: [
                  <Link
                    to="#"
                    key="1"
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                    onClick={() => {
                      setIsAdminDropdownSelectionEnabled(false);
                    }}
                  />,
                ],
              })
            : isAdminAccess
            ? t.el("companyExist", {
                components: [
                  <Link
                    to="#"
                    key="1"
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                    onClick={() => {
                      setIsAdminDropdownSelectionEnabled(true);
                    }}
                  />,
                  <Link
                    key="2"
                    target="_blank"
                    to={CONTACT_US_LINK}
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                  />,
                ],
              })
            : t.el("cantFindCompany", {
                components: [
                  <Link
                    key="3"
                    target="_blank"
                    to={CONTACT_US_LINK}
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                  />,
                ],
              })}
        </Ui.s>

        <Button
          className="mt-3 mb-7"
          isLoading={isLoading}
          isDisabled={isLoading || isNil(selectedCompany)}
          iconRight={<ChevronRightIcon />}
          onClick={submitHandler}
        >
          {t("continue")}
        </Button>
      </div>

      <div className={classes["img-wrap"]}>
        <Image src={Img} />
      </div>
    </div>
  );
};

export default AroOnboarding;
