import { FC, memo } from "react";

import { Ui } from "common/components/atoms";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers/useFormatNumbers";
import { sortingParams } from "common/types/Collapsible.types";
import { transformDateToCommonDateFormat } from "common/utils/functions";

import { Transaction } from "../types";
import { TransactionsTableKeys, TransactionsTableSorting } from "./types";

type PT = {
  sort: TransactionsTableSorting;
  transactions: Transaction[];
};

const TransactionsTableBody: FC<PT> = ({ sort, transactions }) => {
  const currency = useCurrencyById(1); // NOK
  const fNumber = useFormatNumbers(currency.currencySymbol);

  const sortedTransactions = transactions.sort((a, b) => {
    if (sort.field === TransactionsTableKeys.date) {
      if (sort.sortDirection === sortingParams.inc) {
        return a.transactedAt < b.transactedAt ? 1 : -1;
      }

      return a.transactedAt < b.transactedAt ? -1 : 1;
    }

    return 1;
  });

  return (
    <tbody>
      {sortedTransactions.map((transaction) => (
        <tr key={transaction.transactionId}>
          <td>
            <Ui.s>{transformDateToCommonDateFormat(transaction.transactedAt)}</Ui.s>
          </td>
          <td>
            <Ui.s>{transaction.categoryName}</Ui.s>
          </td>
          <td>
            <Ui.s>{transaction.typeName}</Ui.s>
          </td>
          <td>
            <Ui.s>{transaction.aroTransactionType}</Ui.s>
          </td>
          <td className="text-end">
            <Ui.s>{fNumber(transaction.numberOfShares, "amount")}</Ui.s>
          </td>
          <td className="text-end">
            <Ui.s>{fNumber(transaction.nominalValue, "unitPrice")}</Ui.s>
          </td>
          <td className="text-end">
            <Ui.s>{fNumber(transaction.sharePrice, "unitPrice")}</Ui.s>
          </td>
          <td className="text-end">
            <Ui.s>{fNumber(transaction.numberOfShares * transaction.sharePrice, "value")}</Ui.s>
          </td>
        </tr>
      ))}
      {sortedTransactions.length === 0 && (
        <tr>
          <td>
            <Ui.s>Tom</Ui.s>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )}
    </tbody>
  );
};

export default memo(TransactionsTableBody);
