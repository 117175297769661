import { useFormikContext } from "formik";

import { Helper, P, TextField } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { CompanyInformation } from "../types";
import { companyInformationFields as f } from "../use-company-information-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.companyInformation.body.generalInformation");

const GeneralInformation = () => {
  const { values, errors, touched, handleChange } = useFormikContext<CompanyInformation>();

  return (
    <Helper>
      <div className="d-flex flex-column gap-3">
        <P.s color="foregroundMedium">{t("paragraph")}</P.s>
        <Helper.Question questionId={f.companyName} type="input">
          <TextField
            label={t("companyName")}
            name={f.companyName}
            value={values[f.companyName]}
            error={errors[f.companyName]}
            isTouched={touched[f.companyName]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.companyName}>companyName</Helper.Answer>

        <Helper.Question questionId={f.organizationNumber} type="input">
          <TextField
            isDisabled
            label={t("organizationNumber")}
            name={f.organizationNumber}
            value={values[f.organizationNumber]}
            error={errors[f.organizationNumber]}
            isTouched={touched[f.organizationNumber]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.organizationNumber}>organizationNumber</Helper.Answer>

        <Helper.Question questionId={f.postCode} type="input">
          <TextField
            label={t("postCode")}
            name={f.postCode}
            value={values[f.postCode]}
            error={errors[f.postCode]}
            isTouched={touched[f.postCode]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.postCode}>postCode</Helper.Answer>

        <Helper.Question questionId={f.postCity} type="input">
          <TextField
            label={t("postCity")}
            name={f.postCity}
            value={values[f.postCity]}
            error={errors[f.postCity]}
            isTouched={touched[f.postCity]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.postCity}>postCity</Helper.Answer>
      </div>
    </Helper>
  );
};

export default GeneralInformation;
