import { Action, Thunk } from "easy-peasy";

import {
  CompanyInformation,
  CompanyInformationFormValues,
} from "pages/aro/pages/report/aro-steps/company-information/types";
import { Dividends, StakeholderDividend } from "pages/aro/pages/report/aro-steps/dividends/types";
import { CurrentYearSummary, ShareholdersTable } from "pages/aro/pages/report/aro-steps/shareholders/types";
import { FormTransactions, Transactions } from "pages/aro/pages/report/aro-steps/transactions/types";
import {
  PostCalculateDividendsBody,
  PostCompanyInformationResponse,
  PostDividendsModeBody,
  PostTransactionsBody,
  PostTransactionsCheckResponse,
} from "pages/aro/service/types";

export enum AroSectionStatuses {
  "Ikke utfylt", // NotFilledOut
  "Mangler informasjon", // MissingInformation
  Forhåndsutfylt, // PreFilled
  Utfylt, // FilledOut
}

export type AroDetails = {
  year: number;
  companyInformationStatus: AroSectionStatuses;
  altinnConnectionStatus: AroSectionStatuses;
  altinnRegisteredAccess: boolean;
  previousShareholdersStatus: AroSectionStatuses;
  previousShareholdersCount: number;
  transactionsStatus: AroSectionStatuses;
  transactionsCount: number;
  currentShareholdersStatus: AroSectionStatuses;
  currentShareholdersCount: number;
  dividendsStatus: AroSectionStatuses;
};

export interface AroModel {
  // state
  aroDetails?: AroDetails;
  isAroDetailsLoading: boolean;

  companyInformation?: CompanyInformation;
  isCompanyInformationLoading: boolean;

  shareholdersAtYearStart?: ShareholdersTable;
  isShareholdersAtYearStartLoading: boolean;

  transactions?: Transactions;
  isTransactionsLoading: boolean;
  formTransactions?: FormTransactions;
  isFormTransactionsLoading: boolean;

  shareholdersAtYearEnd?: CurrentYearSummary;
  isShareholdersAtYearEndLoading: boolean;

  dividends?: Dividends;
  isDividendsLoading: boolean;
  calculatedDividends?: StakeholderDividend[];

  // actions
  setAroDetails: Action<this, AroDetails>;
  setAroDetailsLoading: Action<this, boolean>;

  setCompanyInformation: Action<this, CompanyInformation>;
  setCompanyInformationLoading: Action<this, boolean>;

  setShareholdersAtYearStart: Action<this, ShareholdersTable>;
  setShareholdersAtYearStartLoading: Action<this, boolean>;

  setTransactions: Action<this, Transactions>;
  setTransactionsLoading: Action<this, boolean>;
  setFormTransactions: Action<this, FormTransactions>;
  setFormTransactionsLoading: Action<this, boolean>;

  setShareholdersAtYearEnd: Action<this, CurrentYearSummary>;
  setShareholdersAtYearEndLoading: Action<this, boolean>;

  setDividends: Action<this, Dividends>;
  setDividendsLoading: Action<this, boolean>;
  setCalculatedDividends: Action<this, StakeholderDividend[]>;

  // thunks
  getAroDetailsThunk: Thunk<this, string, {}, {}, Promise<void>>;
  getCompanyInformationThunk: Thunk<this, string, {}, {}, Promise<void>>;
  getShareholdersAtYearStartThunk: Thunk<this, string, {}, {}, Promise<void>>;
  getTransactionsThunk: Thunk<this, string, {}, {}, Promise<Transactions | null>>;
  getFormTransactionsThunk: Thunk<this, string, {}, {}, Promise<void>>;
  getShareholdersAtYearEndThunk: Thunk<this, string, {}, {}, Promise<void>>;
  getDividendsThunk: Thunk<this, string, {}, {}, Promise<void>>;

  postCompanyInformationThunk: Thunk<
    this,
    { companyId: string; data: CompanyInformationFormValues },
    {},
    {},
    Promise<PostCompanyInformationResponse | null>
  >;
  postTransactionsThunk: Thunk<this, PostTransactionsBody, {}, {}, Promise<PostTransactionsCheckResponse>>;
  postDividendsModeThunk: Thunk<this, PostDividendsModeBody, {}, {}, Promise<boolean>>;
  postCalculateDividendsThunk: Thunk<this, PostCalculateDividendsBody, {}, {}, Promise<boolean>>;
}
