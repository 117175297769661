import { useCallback, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Button, InfoAlert, P } from "common/components/atoms";
import { WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import BodySection from "../../step-container/body-container/body-section";
import ShareholdersTable from "../shareholders-table/shareholders-table";
import ShareClasses from "./share-classes/share-classes";
import ShareholdersEditForm from "./shareholders-edit-form/shareholdes-edit-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body");

const ShareholdersAtYearEndBody = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [isEditTableOpen, setIsEditTableOpen] = useState(false);

  const shareholdersAtYearEnd = useStoreState((state) => state.aroModel.shareholdersAtYearEnd);
  const isLoading = useStoreState((state) => state.aroModel.isShareholdersAtYearEndLoading);
  const getShareholderAtYearEnd = useStoreActions((store) => store.aroModel.getShareholdersAtYearEndThunk);

  useEffect(() => {
    if (companyId) {
      getShareholderAtYearEnd(companyId);
    }
  }, [companyId, getShareholderAtYearEnd]);

  const handleOpenEditTableClick = useCallback(() => {
    setIsEditTableOpen(true);
  }, []);

  const handleCloseEditTableClick = useCallback(() => {
    setIsEditTableOpen(false);
  }, []);

  return (
    <>
      <Accordion flush defaultActiveKey="end">
        <ShareClasses className="mb-2" />
        <BodySection eventKey="end" title={t("listOfShareholders.title")}>
          {(shareholdersAtYearEnd?.missingInformationCount || 0) > 0 ? (
            <InfoAlert
              type="Info"
              className="mb-3"
              customContent={
                <div className="d-flex">
                  <WarningExclamationMarkIcon fontSize={36} className="me-2" color={scssVariables.information700} />
                  <P.s>{t("warningPlate.paragraph", { count: shareholdersAtYearEnd?.missingInformationCount })}</P.s>
                  <Button
                    onClick={handleOpenEditTableClick}
                    variant="information"
                    style={{ backgroundColor: scssVariables.information700, color: scssVariables.surface1 }}
                  >
                    {t("warningPlate.btn")}
                  </Button>
                </div>
              }
            />
          ) : (
            <div className="d-flex justify-content-end mb-3">
              <Button size="s" onClick={handleOpenEditTableClick}>
                {t("warningPlate.btn")}
              </Button>
            </div>
          )}

          <ShareholdersTable
            type="end"
            isLoading={isLoading}
            shareholders={shareholdersAtYearEnd?.shareholders || []}
          />
        </BodySection>
      </Accordion>

      {isEditTableOpen && <ShareholdersEditForm onClose={handleCloseEditTableClick} />}
    </>
  );
};

export default ShareholdersAtYearEndBody;
