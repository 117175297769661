import { FC, useMemo } from "react";
import cn from "classnames";

import CTable from "common/components/atoms/Table/Table";
import { useStoreState } from "store/store";

import classes from "../../../shareholders-table/styles.module.scss";
import { ShareClass } from "../../../types";
import ShareholdersTableBody from "./shareclass-table-body";
import ShareholdersTableHeader from "./shareclass-table-header";
import { HeadColumn } from "./types";

type PT = {
  shareClass: ShareClass;
  className?: string;
};

const ShareClassTable: FC<PT> = ({ shareClass, className }) => {
  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);

  const headColumns: HeadColumn[] = useMemo(
    () => [
      {
        key: "name",
        value: shareClass.name,
      },
      {
        key: "lastYear",
        value: `31.12.${year - 1}`,
      },
      {
        key: "currentYear",
        value: `31.12.${year}`,
      },
    ],
    [shareClass.name, year]
  );

  return (
    <CTable
      className={cn(classes.table, className)}
      headComponent={<ShareholdersTableHeader columns={headColumns} />}
      bodyComponent={<ShareholdersTableBody shareClass={shareClass} />}
    />
  );
};

export default ShareClassTable;
