import { useMemo } from "react";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

import { CompanyInformation, CompanyInformationFormValues } from "./types";

const t = createTranslation(TranslationNS.validation);

export const companyInformationFields: Record<keyof CompanyInformationFormValues, keyof CompanyInformationFormValues> =
  {
    companyName: "companyName",
    organizationNumber: "organizationNumber",
    postCode: "postCode",
    postCity: "postCity",
    contactPersonName: "contactPersonName",
    contactPersonFirm: "contactPersonFirm",
    contactPersonRole: "contactPersonRole",
    contactPersonEmail: "contactPersonEmail",
    contactPersonPhone: "contactPersonPhone",
  } as const;

const useCompanyInformationForm = (initialData?: CompanyInformation) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        companyName: Yup.string().required(t("required")),
        organizationNumber: Yup.string().required(t("required")),
        postCode: Yup.string().required(t("required")),
        postCity: Yup.string().required(t("required")),

        contactPersonName: Yup.string().required(t("required")),
        contactPersonFirm: Yup.string(),
        contactPersonRole: Yup.string().required(t("required")),
        contactPersonEmail: Yup.string().email().required(t("required")),
        contactPersonPhone: Yup.string(),
      }),
    []
  );

  const initialValues: CompanyInformationFormValues = {
    companyName: initialData?.companyName || "",
    organizationNumber: initialData?.organizationNumber || "",
    postCode: initialData?.postCode || "",
    postCity: initialData?.postCity || "",

    contactPersonName: initialData?.contactPersonName || "",
    contactPersonFirm: initialData?.contactPersonFirm || "",
    contactPersonRole: initialData?.contactPersonRole || "", // TODO Roles
    contactPersonEmail: initialData?.contactPersonEmail || "",
    contactPersonPhone: initialData?.contactPersonPhone || "",
  };

  return { validationSchema, initialValues };
};

export default useCompanyInformationForm;
