import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { endOfYear, set, startOfYear } from "date-fns";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button, DatePicker, P, TextField } from "common/components/atoms";
import useCurrencyById from "common/hooks/useCurrencyById";
import { InformationCircleIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../styles.module.scss";
import { CalculateForm } from "../types";

type PT = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body.calculateFields");
const tV = createTranslation(TranslationNS.validation);

const initialValues: CalculateForm = {
  totalDividend: undefined,
  dividendDate: undefined,
};

const validationSchema = Yup.object({
  totalDividend: Yup.number()
    .moreThan(0, () => tV("minNumberMore", { number: 0 }))
    .required(() => tV("required")),
  dividendDate: Yup.string().required(() => tV("required")),
});

const CalculateInputs: FC<PT> = ({ className }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const { currencySymbol } = useCurrencyById(1);

  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);
  const postCalculateDividendsThunk = useStoreActions((actions) => actions.aroModel.postCalculateDividendsThunk);

  const handleSubmit = async (values: CalculateForm) => {
    if (companyId) {
      await postCalculateDividendsThunk({
        companyId,
        dividendDate: values.dividendDate,
        totalDividend: Number(values.totalDividend),
      });
    }
  };

  const dateLimits = useMemo(() => {
    const today = new Date(year, 0, 1);

    const start = set(startOfYear(today), { hours: 0, minutes: 0, seconds: 0 });
    const end = set(endOfYear(today), { hours: 23, minutes: 59, seconds: 59 });

    return { start, end };
  }, [year]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue, submitForm, isSubmitting }) => (
        <div className={cn("rounded-3 p-3", className)}>
          <TextField
            autoFocus
            label={t("total")}
            className="mb-3"
            name="totalDividend"
            type="number"
            value={values.totalDividend}
            error={errors.totalDividend}
            isTouched={touched.totalDividend}
            onChange={handleChange}
            iconRight={currencySymbol}
          />
          <DatePicker
            label={t("date")}
            className="mb-3"
            name="dividendDate"
            isDateOnlyString
            date={values.dividendDate}
            minDate={dateLimits.start}
            maxDate={dateLimits.end}
            error={errors.dividendDate}
            isTouched={touched.dividendDate}
            onChange={(date) => setFieldValue("dividendDate", date)}
          />
          <Button className="mb-3" size="s" isLoading={isSubmitting} onClick={submitForm}>
            {t("submit")}
          </Button>
          <div className={cn("d-flex align-items-center rounded-3 p-2", classes.backgroundElement3)}>
            <InformationCircleIcon fontSize="24px" />
            <P.s className="ms-2">{t("info")}</P.s>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CalculateInputs;
