import { memo, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { endOfYear, set, startOfYear } from "date-fns";
import { useFormikContext } from "formik";

import { Button, DatePicker, TextField } from "common/components/atoms";
import Dropdown, { OptionsType } from "common/components/atoms/Dropdown/Dropdown";
import { DeleteIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../../service/aro-service";
import { StakeholderDividendForm } from "../../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body.table");

const ManualTableBody = memo(() => {
  const { companyId } = useParams<{ companyId: string }>();
  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);
  const shareClasses = useStoreState((state) => state.aroModel.shareholdersAtYearEnd?.shareClasses);

  const { values, errors, touched, setFieldValue } = useFormikContext<{
    stakeholders: StakeholderDividendForm[];
  }>();

  const dateLimits = useMemo(() => {
    const today = new Date(year, 0, 1);

    const start = set(startOfYear(today), { hours: 0, minutes: 0, seconds: 0 });
    const end = set(endOfYear(today), { hours: 23, minutes: 59, seconds: 59 });

    return { start, end };
  }, [year]);

  const [stakeholders, setStakeholders] = useState<OptionsType[]>([]);

  // const filteredStakeholders = useMemo(() => {
  //   const idsOfSelectedStakeholders = new Set(values.stakeholders.map((s) => s.stakeholderId));
  //
  //   return stakeholders.map((s) => (!idsOfSelectedStakeholders.has(Number(s.id)) ? s : { ...s, hidden: true }));
  // }, [stakeholders, values.stakeholders]);

  useEffect(() => {
    const fetchShareholders = async () => {
      if (companyId) {
        try {
          const result = await aroService.getShareholders(companyId);
          if (result) {
            setStakeholders(
              result.stakeholders?.map((shareholder) => ({
                id: shareholder.stakeholderId,
                name: shareholder.shareholderName,
              }))
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    };

    fetchShareholders();
  }, [companyId, shareClasses]);

  const shareClassesWithEmpty = useMemo(() => {
    return shareClasses ? [{ id: 0, name: t("notSelected") }, ...shareClasses] : [];
  }, [shareClasses]);

  return (
    <tbody>
      {values.stakeholders.map((stakeholder, index) => (
        <tr key={stakeholder.key}>
          <td>
            <Dropdown
              name={`stakeholders[${index}].stakeholderId`}
              options={stakeholders}
              optionsIsObject
              selectedValue={stakeholder.stakeholderId}
              onChange={(e) => setFieldValue(`stakeholders[${index}].stakeholderId`, e.target.value)}
              isTouched={touched.stakeholders?.[index]?.stakeholderId}
              // @ts-ignore
              error={errors.stakeholders?.[index]?.stakeholderId}
            />
          </td>
          <td>
            <DatePicker
              name={`stakeholders[${index}].dividendDate`}
              date={stakeholder.dividendDate}
              onChange={(date) => setFieldValue(`stakeholders[${index}].dividendDate`, date)}
              minDate={dateLimits.start}
              maxDate={dateLimits.end}
              isTouched={touched.stakeholders?.[index]?.dividendDate}
              // @ts-ignore
              error={errors.stakeholders?.[index]?.dividendDate}
            />
          </td>
          <td>
            <TextField
              type="number"
              name={`stakeholders[${index}].dividendAmount`}
              value={stakeholder.dividendAmount}
              onChange={(e) => setFieldValue(`stakeholders[${index}].dividendAmount`, e.target.value)}
              isTouched={touched.stakeholders?.[index]?.dividendAmount}
              // @ts-ignore
              error={errors.stakeholders?.[index]?.dividendAmount}
            />
          </td>
          <td>
            <Dropdown
              name={`stakeholders[${index}].shareClassId`}
              options={shareClassesWithEmpty}
              optionsIsObject
              selectedValue={stakeholder.shareClassId}
              onChange={(e) => setFieldValue(`stakeholders[${index}].shareClassId`, e.target.value)}
              isTouched={touched.stakeholders?.[index]?.shareClassId}
              // @ts-ignore
              error={errors.stakeholders?.[index]?.shareClassId}
            />
          </td>
          <td style={{ width: "32px" }}>
            <Button
              isOnlyIcon
              variant="dark"
              className={index === 0 ? "invisible" : undefined}
              onClick={() => {
                const updatedValues = [...values.stakeholders];
                updatedValues.splice(index, 1);
                setFieldValue("stakeholders", updatedValues);
              }}
            >
              <DeleteIcon fontSize="24px" />
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  );
});
export default ManualTableBody;
