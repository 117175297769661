import cn from "classnames";

import { Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import CTable from "common/components/atoms/Table/Table";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../../shareholders-table/styles.module.scss";
import { HeadColumn } from "./types";
const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body.shareClassesTable");

const ShareclassTableLoading = () => {
  const headColumns: HeadColumn[] = [
    {
      key: "name",
      value: "shareclass name",
    },
    {
      key: "lastYear",
      value: "31.12.2000",
    },
    {
      key: "currentYear",
      value: "31.12.2000",
    },
  ];

  return (
    <CTable
      className={cn(classes.table)}
      headComponent={
        <thead>
          <tr>
            {headColumns.map((el) => (
              <th key={el.key}>
                <div
                  className={cn("d-flex justify-content-end", {
                    ["justify-content-start"]: el.key === "name",
                  })}
                >
                  {el.key === "name" ? <Ui.m>{el.value}</Ui.m> : <Ui.xs>{el.value}</Ui.xs>}
                </div>
              </th>
            ))}
          </tr>
        </thead>
      }
      bodyComponent={
        <tbody>
          <tr>
            <td>
              <Ui.xs>{t("row1")}</Ui.xs>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
          </tr>

          <tr>
            <td>
              <Ui.xs>{t("row2")}</Ui.xs>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
          </tr>

          <tr>
            <td>
              <Ui.xs>{t("row3")}</Ui.xs>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
          </tr>

          <tr>
            <td>
              <Ui.xs>{t("row4")}</Ui.xs>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
            <td>
              <Skeleton isLoading={true}>
                <Ui.xs>123 456</Ui.xs>
              </Skeleton>
            </td>
          </tr>
        </tbody>
      }
    />
  );
};

export default ShareclassTableLoading;
