import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { H, TagVariant } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, Languages, TranslationNS } from "translation";

import AroFooter from "./aro-footer/aro-footer";
import AroStepsContainer from "./aro-steps/aro-steps-container";

const t = createTranslation(TranslationNS.pages, "aro.main");

export const StatusTagVariantMapper: Record<number, TagVariant> = {
  0: "closed", // NotFilledOut
  1: "closed", // MissingInformation
  2: "complete", // PreFilled
  3: "complete", // FilledOut
} as const;

const ReportPage = () => {
  const { i18n } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();

  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const getAroDetailsThunk = useStoreActions((store) => store.aroModel.getAroDetailsThunk);

  useEffect(() => {
    i18n.changeLanguage(Languages.no);

    if (companyId) {
      getAroDetailsThunk(companyId);
    }

    return () => {
      i18n.changeLanguage(Languages.en);
    };
  }, [companyId, getAroDetailsThunk, i18n]);

  return (
    <div className="d-flex flex-column align-items-center mx-auto w-75">
      <H.l>
        {t.el("title", {
          components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
          values: { year: year || 2000 },
        })}
      </H.l>
      <AroStepsContainer />
      <AroFooter className="ms-17 mt-2" />
    </div>
  );
};

export default ReportPage;
