import React, {FC, ReactNode, useMemo} from "react";
import classNames from "classnames";
import { isNil } from "ramda";

import { InformationCircleIcon, WarningIcon } from "../../../icons/svg";
import { P, Ui } from "../index";
import classes from "./Banner.module.scss";

export type BannerVariants = "information" | "warning" | "error" | "expired" | "success";

type Props = {
  icon?: JSX.Element;
  title?: string;
  description?: ReactNode;
  variant?: BannerVariants;
  noIcon?: boolean;
} & JSX.IntrinsicElements["div"];

const Banner: FC<Props> = ({ className, variant = "information", description, title, icon, noIcon, ...props }) => {
  const Icon = useMemo(() => {
    if (noIcon) {
      return null;
    }

    if (!isNil(icon)) {
      return icon;
    }

    if (variant === "information" || variant === "expired") {
      return <InformationCircleIcon />;
    }

    if (variant === "error") {
      return <WarningIcon />;
    }

    if (variant === "warning") {
      return <WarningIcon />;
    }
  }, [icon, noIcon, variant]);

  return (
    <div
      className={classNames(className, "d-flex p-2", classes["container"], {
        [classes[variant]]: true,
      })}
      {...props}
    >
      {Icon}

      <div
        className={classNames(
          {
            "ms-2": !noIcon,
          },
          classes["content"]
        )}
      >
        {title ? <Ui.m bold>{title}</Ui.m> : null}

        {description ? <P.s>{description}</P.s> : null}
      </div>
    </div>
  );
};

export default Banner;
