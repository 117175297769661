import React, { Fragment, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { isEmpty, isNil } from "ramda";

import { Banner, Button, ChecksGroup, H, Helper, P, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { AltinnLogoIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, CheckIcon, TriangleIcon } from "common/icons/svg";
import { CONTACT_US_LINK, scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import Img from "../../../onboard/company/assets/card-img-1.png";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "aro.sendDetailsToAltinn");

const SendDetailsToAltinn = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataSent, setIsDataSent] = useState<boolean>(false);
  const [isFilledFormSelected, setIsFilledFormSelected] = useState<boolean>();
  const [requestErrors, setRequestErrors] = useState<string[]>([]);

  const user = useStoreState((store) => store.account?.user);
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);

  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);

  const selectedCompany = useMemo(
    () => user?.companies?.find((el) => el.id === Number(companyId)),
    [companyId, user?.companies]
  );

  const goBackHandler = () => {
    navigate(-1);
  };

  const sendAroRequestHandler = async () => {
    setIsLoading(true);
    setRequestErrors([]);

    try {
      const request = await axios.post<{ success: boolean; messages: string[] }>("/api/aro/send-aro", {
        companyId: Number(companyId),
        completed: isFilledFormSelected,
      });

      if (request.status === 200) {
        getAccountThunk();

        if (request.data.success) {
          setIsDataSent(true);
        } else {
          setRequestErrors(request.data.messages);
        }
      }
    } catch (e) {
      console.error({ e });
      setRequestErrors(["Ukjent feil"]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center mx-auto" style={{ width: "55%" }}>
      <H.l className="mb-3">
        {t.el("title", {
          components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
          values: { year: year || 2024 },
        })}
      </H.l>

      {!isEmpty(requestErrors) ? (
        <Banner
          className="w-100 mb-3"
          variant="error"
          title={t("errorBanner.title")}
          description={
            <div className="d-block">
              <Ui.s className="mt-1 mb-2">{t("errorBanner.sub1")}</Ui.s>

              <ul>
                {requestErrors.map((error, index) => (
                  <li key={index}>
                    <Ui.s>{error}</Ui.s>
                  </li>
                ))}
              </ul>

              <Ui.s className="my-2">
                {t.el("errorBanner.sub2", {
                  components: [
                    <Link
                      key="1"
                      to={CONTACT_US_LINK}
                      target="_blank"
                      style={{ color: scssVariables.critical900, textDecoration: "underline" }}
                    />,
                  ],
                })}
              </Ui.s>

              <Button size="s">{t("errorBanner.buttonTitle")}</Button>
            </div>
          }
        />
      ) : null}

      <div className={classNames("d-flex justify-content-between", classes["container"])}>
        <div className={classes["main-info"]}>
          <div className="d-flex align-items-center">
            <H.s>{t(isDataSent ? "sent" : "readyToSubmit")}</H.s>
            {isDataSent ? <CheckIcon className="ms-1" color={scssVariables.positive500} fontSize={32} /> : null}
          </div>

          <P.l color="foregroundMedium" className="mt-2 mb-3">
            {isDataSent
              ? t.el("registeredInformation", {
                  components: [
                    <Fragment key="1">
                      <br />
                      <br />
                    </Fragment>,
                  ],
                  values: {
                    companyName: selectedCompany?.name,
                    date: format(new Date(), "dd. MMM HH:mm", {
                      locale: nb,
                    }),
                  },
                })
              : t("description", {
                  companyName: selectedCompany?.name,
                })}
          </P.l>

          <ChecksGroup>
            <ChecksGroup.Check
              type="radio"
              checked={isFilledFormSelected}
              label={t("sendFilledForm")}
              description={
                <>
                  <P.s>{t("filledFormDescription")}</P.s>
                  <Helper>
                    <Helper.Question
                      questionId="checkTransactionTypes"
                      UI={(active) => {
                        return (
                          <div className="mt-1" style={{ color: scssVariables.primary1 }}>
                            <TriangleIcon style={{ rotate: active ? "90deg" : "0deg" }} />

                            <a style={{ color: scssVariables.primary1 }} className="text-decoration-underline">
                              {t("checkTransactionTypes")}
                            </a>
                          </div>
                        );
                      }}
                    />

                    <Helper.Answer answerId="checkTransactionTypes" UI={<div>Some Answer</div>} />
                  </Helper>
                </>
              }
              onChange={() => setIsFilledFormSelected(true)}
            />

            <ChecksGroup.Check
              type="radio"
              className="mt-1"
              checked={!isNil(isFilledFormSelected) && !isFilledFormSelected}
              label={t("notFilledForm")}
              description={<P.s>{t("notFilledFormDescription")}</P.s>}
              onChange={() => setIsFilledFormSelected(false)}
            />
          </ChecksGroup>

          <div className="d-flex mt-3">
            {isDataSent ? (
              <Link to="https://info.altinn.no" target="_blank">
                <Button variant="secondary" iconRight={<ArrowRightIcon />}>
                  {t("goToSigning")}
                </Button>
              </Link>
            ) : (
              <Button
                isLoading={isLoading}
                isDisabled={isLoading || isNil(isFilledFormSelected)}
                onClick={sendAroRequestHandler}
              >
                {t("sendToAltinn")}
              </Button>
            )}
          </div>

          <Button className="mt-7" variant="tertiary" iconLeft={<ArrowLeftIcon />} onClick={goBackHandler}>
            {t("backToOverview")}
          </Button>
        </div>

        <div
          className={classNames("d-flex flex-column justify-content-evenly align-items-center", classes["img-section"])}
        >
          <AltinnLogoIcon width={160} height={40} />
          <ArrowUpIcon className="my-2" fontSize={32} color={scssVariables.primary1} />
          <Image src={Img} />
        </div>
      </div>

      <div
        className={classNames("mt-3 h-auto", classes["container"], {
          [classes["bottom"]]: true,
        })}
      >
        <H.xxs>{t("howSignInWorks")}</H.xxs>

        <ul className="mt-2 mb-0">
          <Ui.s color="foregroundMedium" className="mb-2">
            <li> {t("explanation1")}</li>
          </Ui.s>

          <Ui.s color="foregroundMedium" className="mb-2">
            <li> {t("explanation2")}</li>
          </Ui.s>

          <Ui.s color="foregroundMedium" className="mb-2">
            <li> {t("explanation3")}</li>
          </Ui.s>

          <Ui.s color="foregroundMedium">
            <li> {t("explanation4")}</li>
          </Ui.s>
        </ul>
      </div>
    </div>
  );
};

export default SendDetailsToAltinn;
