import { useMemo } from "react";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

import { Shareholder, ShareholderEditFormValues } from "../../types";

const t = createTranslation(TranslationNS.validation);

const NORWAY_COUNTRY_ID = 166;

export const fields = {
  stakeholderId: "stakeholderId",
  shareholderName: "shareholderName",
  isin: "isin",
  numberOfShares: "numberOfShares",
  adresse: "adresse",
  postnummer: "postnummer",
  poststed: "poststed",
  land: "land",
} as const;

const initValue: ShareholderEditFormValues = {
  [fields.stakeholderId]: 0,
  [fields.shareholderName]: "",
  [fields.isin]: "",
  [fields.adresse]: "",
  [fields.postnummer]: undefined,
  [fields.poststed]:undefined,
  [fields.land]: 0,
  [fields.numberOfShares]: 0,
};

const objectSchema = Yup.object().shape({
  // other fields are not editable
  [fields.isin]: Yup.string().required(() => t("required")),
  [fields.adresse]: Yup.string().required(() => t("required")),
  [fields.land]: Yup.string().required(() => t("required")),
  [fields.postnummer]: Yup.string().required(() => t("required")),
  [fields.poststed]: Yup.string().required(() => t("required")),
});

const useEditShareholdersForm = (shareholders: Shareholder[]) => {
  const initialValues: ShareholderEditFormValues[] = useMemo(() => {
    if (shareholders.length === 0) {
      return [initValue];
    }

    return shareholders.map((shareholder) => ({
      [fields.stakeholderId]: shareholder.stakeholderId,
      [fields.shareholderName]: shareholder.shareholderName,
      [fields.isin]: shareholder.isin || "",
      [fields.numberOfShares]: shareholder.numberOfShares,

      [fields.adresse]: shareholder?.address || "",
      [fields.land]: shareholder?.countryId || NORWAY_COUNTRY_ID,
      [fields.postnummer]: shareholder?.postalCode || undefined,
      [fields.poststed]: shareholder?.postalCity || undefined,
    }));
  }, [shareholders]);

  const validationSchema = useMemo(() => {
    return Yup.array().of(objectSchema);
  }, []);

  return { validationSchema, initialValues };
};

export default useEditShareholdersForm;
